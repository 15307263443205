import React from 'react'
import Hero from '../components/Hero'
import Banner from '../components/Banner';
import {Link} from 'react-router-dom'
import Services from '../components/services';
import FeaturedRooms from '../components/FeaturedRooms';


export default function Home() {
  return (
    <>
    <Hero>
      <Banner title="luxurious rooms" subtitle="deluxe room starting at 299">
        <Link to='/rooms' className='btn-primary'>
        Our Rooms
        </Link>
      </Banner>
    </Hero>
    <Services />
    <FeaturedRooms/>
 
    </>
  )
}

Hero.defaultProps ={
  hero: "defaultHero"
};