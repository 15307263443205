import React from 'react'
import Hero from '../components/Hero'
import Banner from '../components/Banner'
import {Link} from 'react-router-dom'
import defaultImg from '../images/room-1.jpeg'
import RoomContainer from '../components/RoomContainer'

function Rooms() {
  return (
    <>
    <Hero hero="roomsHero">
      <Banner title="Our Rooms">
        <Link to='/' className='btn-primary'>
        Back to home
        </Link>
      </Banner>
    </Hero>
    <RoomContainer/>
    
</>
  )
}

export default Rooms
