import React from 'react';
import './App.css';

import Home from "./pages/Home";
import Rooms from "./pages/Rooms";
import SingleRoom from "./pages/SingleRoom";
import Error from "./pages/Error";
import Navbar from './components/Navbar';

import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    
    <BrowserRouter>
    <Navbar/>
      <Routes>
        
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/rooms/" element={<Rooms/>}></Route>
        <Route exact path="/rooms/:slug" element={<SingleRoom/>}></Route>
        <Route path="*" element={<Error />}></Route>
          

          
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
