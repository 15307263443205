import React, { Component } from 'react'
import Title from './Title'
import {FaCocktail,FaHiking,FaShuttleVan,FaBeer} from 'react-icons/fa';

export default class services extends Component {
    state={
        services:[
            {
                icon:<FaCocktail/>,
                title:"Free Coctails",
                info:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. standard dummy text ever since the 1500s"
            },
            {
                icon:<FaHiking/>,
                title:"Endless Hiking",
                info:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. standard dummy text ever since the 1500s"
            },
            {
                icon:<FaShuttleVan/>,
                title:"Free Coctails",
                info:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. standard dummy text ever since the 1500s,"
            },
            {
                icon:<FaBeer/>,
                title:"Strongest Beer",
                info:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. standard dummy text ever since the 1500s,"
            },
        
        ]
    };
  render() {
    return (
      <div>
        <section  className='services'>
        <Title title="services"/>
        <div className='services-center'>
            {this.state.services.map((item,index)=>{
                return <article key={index} className="service">
                    <span>{item.icon}</span>
                    <h6>{item.title}</h6>
                    <p>{item.info}</p>
                </article>
            })}
        </div>
        </section>
        
        
      </div>
    )
  }
}
