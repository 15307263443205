import React, { Component } from 'react'
import deafaultBcg from '../images/room-1.jpeg'
import Hero from '../components/Hero'
import Banner from '../components/Banner'
import {Link} from 'react-router-dom'
import { RoomContext } from '../context'
import { useParams } from 'react-router-dom'
import StyledHero from '../components/StyledHero';

const withRouter = WrappedComponent => props => {
  const params = useParams();
 
  return (
    <WrappedComponent
      {...props}
      params={params}
    />
  );
};

class SingleRoom extends Component {
  constructor(props){
   
  
    super(props)
     console.log(this.props);
     
    // console.log(this.props.match.params.slug )

    this.state= {
      slug: this.props.params.slug, deafaultBcg
    };
  }

  // componentDidMount(){}

  static contextType = RoomContext;






  render() {
    const {getRoom} = this.context;
    const room = getRoom(this.state.slug);
    if(!room){
      return (
        <div className='error'>
        <h3>No such room could be found</h3>
        <Link to='/rooms' className='btn-primary'>Back to room</Link>
      </div>
      );
    }

    const{name,description,capacity,size,price,extras,breakfast,pets,images} = room  
    const [mainImg,...defaultImg] = images
    return (
      <>      
      <StyledHero img={images[0] } >
        <Banner title={`${name} name`}>
          <Link to='/rooms' className='btn-primary'>Back to rooms</Link>
        </Banner>

      </StyledHero>
      <section className='single-room'>
        <div className='single-room-images'>
          {defaultImg.map((item,index)=>{
            return <img key={index} src={item} alt={name}/>
          })}
        </div>
        <div className='single-room-info'>
          <article className='desc'>
            <h3>Details</h3>
            <p> {description}</p>
          </article>
          <article className='info'>
            <h3>info</h3>
            <h6>Price: ${price}</h6>
            <h6>Size: ${size}</h6>
            <h6>Max capacity: {capacity>1 ? `${capacity} people` : `${capacity} person`}</h6>
            <h6>{pets?"pets allowed":"no pets allowed"}</h6>
            <h6>{breakfast && "free breakfast included"}</h6>
          </article>
        </div>
      </section>
      <section className='room-extras'>
        <h6>Extras</h6>
        <ul className='extras'>
          {extras.map((item,index) =>{
            return <li key={index}>- {item}</li>
          })}

        </ul>
      </section>
      </>

    )
  }
}

export default withRouter(SingleRoom);